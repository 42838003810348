<template>
    <div>
      <transition name="vload">
        <div v-show="isLoading" class="Loader">
          <div class="sk-cube-grid">
            <div class="sk-cube sk-cube1"></div>
            <div class="sk-cube sk-cube2"></div>
            <div class="sk-cube sk-cube3"></div>
            <div class="sk-cube sk-cube4"></div>
            <div class="sk-cube sk-cube5"></div>
            <div class="sk-cube sk-cube6"></div>
            <div class="sk-cube sk-cube7"></div>
            <div class="sk-cube sk-cube8"></div>
            <div class="sk-cube sk-cube9"></div>
          </div>
          <!--<i class="fa fa-spinner fa-spin fa-5x fa-fw" style="color:green"></i>-->
        </div>
      </transition>
        <!-- ============================================================== -->
        <!-- Main wrapper - style you can find in pages.scss -->
        <!-- ============================================================== -->
        <div id="main-wrapper">
          <Header></Header>
          <section class="fixed mypage-contents-fixed">
            <div class="container">
              <div class="row">
                <MyPageContents class="col-lg-3 col-sm-12" v-on:logout="logout" v-on:delete-account="deleteAccount" :user-data="userData" :validation-errors="deleteAccountValidationErrors"></MyPageContents>
                <div v-if="companyData" class="col-lg-9 col-sm-12 mx-auto mypage-right-frame">
                  <div class="form-title-group">
                    <div class="menu-active-mark" style="margin-top: 10px;"/>
                    <div class="form-title"><h1>企業管理</h1></div>
                  </div>
                  <div class="menu-bar"/>
                  <p class="form-text">企業情報の確認・編集を行うことができます。</p>
                  <div class="_large_jb_list72 shadow_0" style="margin-bottom: 15px">
                    <div class="_large_jb_header">
                      <div class="_list_110">
                        <div class="_list_110_caption">
                          <div class="company-top-wrap">
                            <div v-if="companyData.isVisible" class="jb_types parttime position_setting" style="margin-right: 10px; margin-top: 2px; background-color: rgb(77, 181, 106);" >企業リスト掲載中</div>
                            <div v-if="companyData.recruitmentStatus !== ''" class="company-top">
                              <div v-if="companyData.recruitmentStatus === 1" class="jb_types parttime position_setting">パートナー企業積極募集中！</div>
                              <div v-if="companyData.recruitmentStatus === 2" class="jb_types parttime position_setting">案件に強いパートナー企業募集中！</div>
                              <div v-if="companyData.recruitmentStatus === 3" class="jb_types parttime position_setting">要員に強いパートナー企業募集中！</div>
                              <div v-if="companyData.recruitmentStatus === 4" class="jb_types parttime position_setting">パートナー企業募集停止中</div>
                            </div>
                          </div>
                          <div class="company-name"><h4 class="_jb_title">{{companyData.name}}</h4></div>
                          <div class="_emp_jb">所在地：{{companyData.address}}</div>
                          <div class="_large_jb_body_list_info">
                            <ul>
                              <li v-if="companyData.foundedYear" class="letter-space">設立：{{companyData.foundedYear}}年</li>
                              <li v-if="companyData.capital" class="letter-space">資本金： {{Number(companyData.capital).toLocaleString()}}円</li>
                              <li v-if="companyData.employeeNum" class="letter-space">従業員数： {{companyData.employeeNum}}人</li>
                              <li v-if="companyData.phoneNumber" class="letter-space">電話番号： {{companyData.phoneNumber}}</li>
                              <li v-if="companyData.isBranchOffice" class="letter-space">支店・支社有無：あり</li>
                              <li v-if="companyData.isDispatchLicense" class="letter-space">一般派遣免許：あり</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="companyData.companyFeatures.length > 0" class="_large_jb_body2">
                      <div class="_large_jb_body_list_explain">
                        <div class="jb_req_skill">
                          <ul>
                            <li v-for="featureType of getFeatureTypes(companyData.companyFeatures)" :key="featureType"><span>{{getFeatureText(featureType)}}</span></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <router-link class="btn-md full-width pop-login mypage-menu mypage-menu-under" to="/editcompany">企業編集</router-link>
                </div>
              </div>
            </div>
          </section>
          <Footer></Footer>
        </div>
    </div>
</template>
<script>
    import Header from "@/views/components/Header";
    import Footer from "@/views/components/Footer";
    import MyPageContents from "@/views/components/MyPageContents";
    import MyPageMixIn from "@/common/mypage_mixin";

    const featureText = [
        "エンド直案件あり",
        "元請け直案件あり",
        "ロースキル案件あり",
        "自社内・グループ会社内案件あり",
        "社員・プロパー営業あり",
        "直フリーランスの扱いあり",
        "業務系に強い",
        "Web系に強い",
        "ゲーム系に強い"
    ]

    export default {
        name: "MyPage",
        components: {Footer, Header, MyPageContents},
        mixins: [MyPageMixIn],
        computed: {
            isOwner: {
                get() {
                    if (!this.userData || !this.userData.userGroup) {
                        return false;
                    }
                    return this.userData.id.toString() === this.userData.userGroup.ownerId.toString();
                }
            }
        },
        methods: {
            getFeatureTypes: function(companyFeatures) {
                if (!companyFeatures) {
                    return []
                }
                return companyFeatures.map(companyFeature => Number(companyFeature.feature));
            },
            getFeatureText: function(featureType) {
                return featureText[featureType];
            },
        }
    }
</script>